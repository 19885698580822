import React, {useState, useContext, useEffect} from 'react';
import AuthContext from '../Store/auth-context';
import WorkspaceForm from './WorkspaceForm';
import {Link, NavLink} from 'react-router-dom';
const Workspaces = () => {
    const [groups, setGroups] = useState([]);
    const authCtx = useContext(AuthContext);
    useEffect(() => {
        fetch('/api/groups/', {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((new_groups) => {
                setGroups(new_groups);
            });
    }, []);
    const addGroupHandler = (data) => {
        var new_array = groups.slice();
        new_array.push(data);
        setGroups(new_array);
    };
    const deleteGroupHandler = (event) => {
        fetch('/api/del_group/', {
            method: 'POST',
            body: JSON.stringify({id: event.target.id}),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    var new_array = groups.slice();

                    setGroups(
                        new_array.filter((x) => {
                            return x.id !== data.id;
                        }),
                    );
                }
            })
            .catch((error) => console.log(error));
    };
    const addFriend = (event) => {
        fetch('/api/join_group/', {
            method: 'POST',
            body: JSON.stringify({
                id: event.target.id,
                group_id: event.target.dataset.group_id,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => console.log(error));
    };
    return (
        <div class='container'>
            {authCtx.isLoggedIn && (
                <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 g-3 m-2 '>
                    {groups &&
                        groups.map((group) => {
                            return (
                                <div class='d-flex justify-content-center '>
                                    <div
                                        class='card w-75 bg-secondary'
                                        key={group.id}
                                    >
                                        <div class='card-header d-flex justify-content-between'>
                                            <div class='dropdown'>
                                                <a
                                                    class='btn btn-tableau bg-light dropdown-toggle btn-sm'
                                                    href='#'
                                                    role='button'
                                                    id='dropdownMenuLink'
                                                    data-bs-toggle='dropdown'
                                                    aria-expanded='false'
                                                >
                                                    Mitglieder
                                                </a>

                                                <ul
                                                    class='dropdown-menu'
                                                    aria-labelledby='dropdownMenuLink'
                                                >
                                                    {group.group_members &&
                                                        group.group_members.map(
                                                            (item) => {
                                                                return (
                                                                    <li>
                                                                        <a
                                                                            id={
                                                                                item[0]
                                                                            }
                                                                            class='dropdown-item'
                                                                            href='#'
                                                                        >
                                                                            {
                                                                                item[1]
                                                                            }
                                                                        </a>
                                                                    </li>
                                                                );
                                                            },
                                                        )}

                                                    {/*  */}
                                                </ul>
                                            </div>
                                            <div class='dropdown'>
                                                <a
                                                    class='btn btn-tableau bg-light dropdown-toggle btn-sm'
                                                    href='#'
                                                    role='button'
                                                    id='dropdownMenuLink'
                                                    data-bs-toggle='dropdown'
                                                    aria-expanded='false'
                                                >
                                                    Freunde hinzufügen
                                                </a>

                                                <ul
                                                    class='dropdown-menu'
                                                    aria-labelledby='dropdownMenuLink'
                                                >
                                                    {group.friends &&
                                                        group.friends.map(
                                                            (item) => {
                                                                return (
                                                                    <li>
                                                                        <a
                                                                            id={
                                                                                item[0]
                                                                            }
                                                                            data-group_id={
                                                                                group.id
                                                                            }
                                                                            class='dropdown-item add_friend'
                                                                            onClick={
                                                                                addFriend
                                                                            }
                                                                            href='#'
                                                                        >
                                                                            {
                                                                                item[1]
                                                                            }
                                                                        </a>
                                                                    </li>
                                                                );
                                                            },
                                                        )}

                                                    {/*  */}
                                                </ul>
                                            </div>
                                        </div>
                                        <NavLink
                                            to={'/posts/' + group.id + '/all'}
                                        >
                                            <a href='#'>
                                                <img
                                                    src='https://cdn.pixabay.com/photo/2018/03/10/18/03/laptop-3214756_960_720.png'
                                                    class='card-img-top'
                                                    alt='...'
                                                ></img>
                                            </a>
                                        </NavLink>

                                        <div class='card-footer d-flex justify-content-between'>
                                            <div className='my-auto'>
                                                <Link
                                                    to={'/posts/' + group.id}
                                                    className='link-secondary navigation'
                                                >
                                                    {group.group_name}
                                                </Link>
                                            </div>
                                            <div className='my-auto'>
                                                <button
                                                    id={group.id}
                                                    onClick={deleteGroupHandler}
                                                    className='btn btn-danger  btn-sm'
                                                >
                                                    {group.group_members
                                                        .length > 1
                                                        ? 'Austreten'
                                                        : 'Löschen'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    <br></br>
                    <WorkspaceForm
                        token={authCtx.token}
                        addGroupHandler={addGroupHandler}
                    ></WorkspaceForm>
                </div>
            )}
        </div>
    );
};

export default Workspaces;
