import {useState, useContext} from 'react';
import AuthContext from '../Store/auth-context';

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [staylogged, setStaylogged] = useState(false);
    const authCtx = useContext(AuthContext);
    const loginHandler = (event) => {
        event.preventDefault();
        if ((username.length > 5) & (password != '')) {
            console.log('Login');
            let expirationTime = new Date();
            if (staylogged) {
                expirationTime = new Date(
                    new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
                );
            } else {
                expirationTime = new Date(
                    new Date().getTime() + 1 * 60 * 60 * 1000,
                );
            }

            console.log(expirationTime);
            authCtx.login(
                {username: username, password: password},
                expirationTime,
            );
        }
    };
    const usernameChangeHandler = (event) => {
        setUsername(event.target.value);
    };
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    };
    const checkHandler = (event) => {
        setStaylogged(event.target.checked);
    };
    return (
        <div class='container'>
            <br></br>
            <div className='row m-2'>
                <div className='col-6 offset-3'>
                    <form onSubmit={loginHandler}>
                        <div className='form-group'>
                            <input
                                className='form-control'
                                type='text'
                                value={username}
                                onChange={usernameChangeHandler}
                                placeholder='Benutzername'
                            ></input>
                        </div>
                        <br></br>
                        <div className='form-group'>
                            <input
                                className='form-control'
                                value={password}
                                onChange={passwordChangeHandler}
                                type='password'
                                placeholder='Passwort eingeben'
                            ></input>
                        </div>
                        <br></br>
                        <div class='form-check'>
                            <input
                                class='form-check-input'
                                type='checkbox'
                                value=''
                                onClick={checkHandler}
                                id='flexCheckDefault'
                            ></input>
                            <label
                                class='form-check-label'
                                for='flexCheckDefault'
                            >
                                Eingeloggt bleiben?
                            </label>
                        </div>
                        <br></br>
                        <button class='btn btn-primary' type='submit'>
                            einloggen
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
