import {useState, useEffect, useCallback, useContext} from 'react';
import PostList from './PostList';
import TopicList from './TopicList';
import './PostSite.css';
import AuthContext from '../Store/auth-context';
import PostForm from './PostForm';

import {
    getTreeFromFlatData,
    getFlatDataFromTree,
    isDescendant,
} from 'react-sortable-tree';
import {useParams} from 'react-router-dom';

function PostsSite(props) {
    const params = useParams();
    //console.log(params.topic_id);
    //console.log(params.workspace_id);
    const authCtx = useContext(AuthContext);
    const [posts, setPosts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const [posts_filtered, filterPosts] = useState([]);
    const [topics, setTopics] = useState([]);
    const [flatdata, setFlatdata] = useState([]);
    const [currentTopicId, setCurrentTopicId] = useState(undefined);
    const [workspaceId, setWorkspaceId] = useState(undefined);

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        fetch('/api/groups/', {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((new_groups) => {
                console.log('New Groups set!');
                //const result =
                if (workspaceId != undefined) {
                    const result = new_groups.filter((item) => {
                        return item.id !== parseInt(workspaceId);
                    });
                    setGroups(result);
                } else {
                    setGroups(new_groups);
                }
            });
    }, [workspaceId]);

    // Modify the current state by setting the new data to
    // the response from the backend
    useEffect(() => {
        console.log('TRIGGGERED!!!');
        setWorkspaceId(params.workspace_id);
        fetch('/api/posts/' + params.workspace_id, {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setLoaded(true);
                setPosts(response);
                setTimeout(function () {
                    console.log('NOW');
                    setVisibility(true);
                }, 1000);
            })
            .catch((error) => console.log(error));
    }, [authCtx.token]);

    useEffect(() => {
        if (params.workspace_id != workspaceId) {
            fetchTopicsHandler();
        }
    }, [params.workspace_id]);

    useEffect(() => {
        if (props.term !== '') {
            const filtered_posts = posts.filter((x) => {
                return x.body.toLowerCase().includes(props.term.toLowerCase());
            });
            // Detected POSTS:
            console.log('Detected POSTS');
            console.log(filtered_posts);
            filterPosts(filtered_posts);
        }
    }, [props.term]);

    const fetchTopicsHandler = useCallback(() => {
        fetch('/api/topics/' + params.workspace_id, {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setTopics(data);
                const tree_data = getTreeFromFlatData({
                    flatData: data.map((node) => ({
                        ...node,
                        expanded: false,
                        title: node.label,
                    })),
                    getKey: (node) => node.id, // resolve a node's key
                    getParentKey: (node) => {
                        if (node.parent_id === node.id) {
                            return '0';
                        } else {
                            return node.parent_id;
                        }
                    }, // resolve a node's parent's key
                    rootKey: '0', // The value of the parent key when there is no parent (i.e., at root level)
                });
                const flat_data = getFlatDataFromTree({
                    treeData: tree_data,
                    getNodeKey: ({treeIndex}) => treeIndex,
                    ignoreCollapsed: false,
                });
                // const path = flat_data
                //     .filter((topic) => {
                //         console.log(topic.node);
                //         return topic.node.id === 9;
                //     })[0]
                //     .path.toString();
                // console.log(flat_data);
                // console.log('DSAdadas');
                // console.log(
                //     flat_data.filter((topic) => {
                //         return topic.path.toString().includes(path);
                //     }),
                // );

                setFlatdata(flat_data);
            });
    });

    const deletePostHandler = (data) => {
        setPosts(
            posts.filter((post) => {
                return post.id !== data.post_id;
            }),
        );
        updatePosts(data.topic_id);

        document.getElementById(String(data.topic_id)).click();
    };
    const editPostHandler = (data) => {
        const new_posts = posts.filter((post) => {
            return post.id !== data.id;
        });
        new_posts.push(data);

        setPosts(new_posts);
        updatePosts(data.topic_id);
        document.getElementById(String(data.topic_id)).click();
    };
    const addPostHandler = (data) => {
        posts.push(data);

        setPosts(posts);
        updatePosts(data.topic_id);
    };

    useEffect(() => {
        if (
            (Object.keys(topics).length != 0) &
            (Object.keys(posts).length != 0) &
            (currentTopicId === undefined)
        ) {
            console.log('FIRST LOAD');
            // console.log(posts);
            // console.log(
            //     posts.sort(function (a, b) {
            //         return new Date(b.created) - new Date(a.created);
            //     }),
            // );
            // const filtered_posts = posts.filter((x) => {
            //     return x.topic_id === topics[0].id;
            // });
            const filtered_posts = posts
                .sort(function (a, b) {
                    return new Date(b.created) - new Date(a.created);
                })
                .slice(0, 20);
            filterPosts(filtered_posts);
        }
    }, [posts, topics]);

    const updatePosts = (topic_id, children = false) => {
        if (children) {
            if (flatdata.length > 0) {
                const path = flatdata
                    .filter((topic) => {
                        return topic.node.id === parseInt(topic_id);
                    })[0]
                    .path.toString();

                const all_children = flatdata
                    .filter((topic) => {
                        return topic.path.toString().startsWith(path);
                    })
                    .map((x) => {
                        return x.node.id;
                    });

                setCurrentTopicId(topic_id);
                const new_posts = posts.filter((x) => {
                    return all_children.includes(x.topic_id);
                });

                filterPosts(new_posts);
            }
        } else {
            setTimeout(function () {
                console.log('updating posts!');
                setCurrentTopicId(topic_id);
                const new_posts = posts.filter((x) => {
                    return x.topic_id === parseInt(topic_id);
                });
                filterPosts(new_posts);
            }, 200);
        }
    };
    const updateTopics = (new_topic) => {
        // fields = ("id", "label", "parent_id","user_id")
        topics.push(new_topic);
        setTopics(topics);
    };
    const expandNavi = (topic_id) => {
        setCurrentTopicId(topic_id);
    };
    return (
        <div className='container-fluid'>
            <div class='row'>
                <div class='sidebar col-3 '>
                    <TopicList
                        topics={topics}
                        topic_id={currentTopicId}
                        updateTopics={updateTopics}
                        token={authCtx.token}
                        onClickTopic={updatePosts}
                        workspace_id={params.workspace_id}
                        url_topic_id={params.topic_id}
                    />
                </div>

                <div
                    data-workspace={params.workspace_id}
                    className={
                        visibility === false
                            ? 'mainpanel col-9 hidden'
                            : 'mainpanel col-9'
                    }
                >
                    <PostForm
                        posts={posts_filtered}
                        topics={topics}
                        groups={groups}
                        topic_id={currentTopicId}
                        addPostFrontend={addPostHandler}
                        updatePosts={updatePosts}
                        token={authCtx.token}
                    ></PostForm>
                    {loaded === false && (
                        <div class='d-flex justify-content-center'>
                            <div class='spinner-border m-5' role='status'>
                                <span class='sr-only'>Loading...</span>
                            </div>
                        </div>
                    )}
                    <PostList
                        delPostFrontend={deletePostHandler}
                        editPostFrontend={editPostHandler}
                        expandNavi={expandNavi}
                        updatePosts={updatePosts}
                        posts={posts_filtered}
                        topics={topics}
                        token={authCtx.token}
                    />
                </div>
            </div>
        </div>
    );
}

export default PostsSite;
