import React, {useState, useEffect} from 'react';

let logoutTimer;
const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    role: '',
    login: (username, password) => {},
    logout: () => {},
});
// create app wide state:
const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();
    const remainingDuration = adjExpirationTime - currentTime;
    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedRole = localStorage.getItem('role');
    const storedExpirationDate = localStorage.getItem('expirationTime');
    const remainingTime = calculateRemainingTime(storedExpirationDate);
    if (remainingTime <= 60000) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('expirationTime');
        return null;
    }
    return {token: storedToken, duration: remainingTime, role: storedRole};
};

export const AuthContextProvider = (props) => {
    const tokenData = retrieveStoredToken();
    let initialToken;
    let initialRole;
    if (tokenData) {
        initialToken = tokenData.token;
        initialRole = tokenData.role;
    }
    const [token, setToken] = useState(initialToken);
    const [role, setRole] = useState(initialRole);
    const userIsLoggedIn = !!token;

    const logoutHandler = () => {
        fetch('/api/logout/', {
            method: 'POST',
            body: JSON.stringify({token: 'test'}),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setToken(null);
                setRole('');
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                window.history.replaceState('', '', '/');

                window.location.reload();
                if (logoutTimer) {
                    clearTimeout(logoutTimer);
                }
            });
    };
    const loginHandler = (credentials, expirationTime) => {
        const remainingTime = calculateRemainingTime(expirationTime);

        fetch('/api/token/', {
            method: 'POST',
            body: JSON.stringify({
                username: credentials.username,
                password: credentials.password,
                remainstatus:
                    remainingTime < 24 * 60 * 60 * 1000 ? false : true,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data != 'failure') {
                    setToken(data['access_token']);
                    setRole(data['role']);
                    localStorage.setItem('role', data['role']);
                    localStorage.setItem('token', data['access_token']);
                    localStorage.setItem('expirationTime', expirationTime);
                    if (remainingTime < 24 * 60 * 60 * 1000) {
                        logoutTimer = setTimeout(logoutHandler, remainingTime);
                    }

                    //window.history.replaceState('', '', '/posts');
                    //window.location.href = window.location.href;
                    document.getElementsByClassName('nav-link')[0].click();
                    //window.location.reload();
                }
            });
    };
    const registerHandler = (credentials) => {
        fetch('/api/register/', {
            method: 'POST',
            body: JSON.stringify({
                username: credentials.username,
                password: credentials.password,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data != 'failure') {
                    document.getElementsByClassName('nav-link')[1].click();
                    //window.location.reload();
                }
            });
    };
    useEffect(() => {
        if (tokenData) {
            if (tokenData.duration < 24 * 60 * 60 * 1000) {
                logoutTimer = setTimeout(logoutHandler, tokenData.duration);
            }

            console.log(tokenData.duration);
        }
    }, [tokenData]);

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        role: role,
        login: loginHandler,
        register: registerHandler,
        logout: logoutHandler,
    };
    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
