import React, {Component, useContext} from 'react';
import SortableTree, {map} from 'react-sortable-tree';
import getNodeKey from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import {
    getTreeFromFlatData,
    getFlatDataFromTree,
    addNodeUnderParent,
    getNodeAtPath,
    find,
    toggleExpandedForAll,
    walk,
    changeNodeAtPath,
    removeNodeAtPath,
} from 'react-sortable-tree';
import './TopicList.css';

export default class TopicList extends Component {
    constructor(props) {
        super(props);
        this.removeNode = this.removeNode.bind(this);
        this.state = {
            workspace_id: props.workspace_id,
            url_topic_id: props.url_topic_id,
            treeData: {title: 'loading'},
            topic_id: props.topic_id,
            expandable: false,
            draggable: true,
            loaded: false,
            token: props.token,
            changable: false,
            searchQuery: '',
        };
    }
    addNode(rowInfo) {
        let NEW_NODE = {title: '', state: 'new', number_of_posts: 0};

        // let {node, treeIndex, path} = rowInfo;

        // path.pop();
        // let parentNode = getNodeAtPath({
        //     treeData: this.state.treeData,
        //     path: path,
        //     getNodeKey: ({treeIndex}) => treeIndex,
        //     ignoreCollapsed: true,
        // });
        // let getNodeKey = ({node: object, treeIndex: number}) => {
        //     return number;
        // };
        // let parentKey = getNodeKey(parentNode);
        // console.log(parentKey);
        // if (parentKey == -1) {
        //     parentKey = null;
        // }
        let newTree = addNodeUnderParent({
            treeData: this.state.treeData,
            newNode: NEW_NODE,
            expandParent: true,
            parentKey: rowInfo ? rowInfo.treeIndex : undefined,
            getNodeKey: ({treeIndex}) => treeIndex,
        });
        this.setState({treeData: newTree.treeData});
    }
    addSameLevelNode() {
        let NEW_NODE = {title: '', state: 'new', number_of_posts: 0};
        let newTree = addNodeUnderParent({
            treeData: this.state.treeData,
            newNode: NEW_NODE,
            expandParent: true,
            parentKey: undefined,
            getNodeKey: ({treeIndex}) => treeIndex,
        });
        this.setState({treeData: newTree.treeData});
    }

    removeNode(rowInfo) {
        let {node, treeIndex, path} = rowInfo;

        let currentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path: path,
            getNodeKey: ({treeIndex}) => treeIndex,
            ignoreCollapsed: true,
        });

        if (node.number_of_posts === 0) {
            fetch('/api/del_topic/', {
                method: 'POST',
                body: JSON.stringify({id: currentNode.node.id}),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.state.token,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data['msg']) {
                        alert(data['msg']);
                    } else {
                        console.log(currentNode.node.id);
                        this.setState({
                            treeData: removeNodeAtPath({
                                treeData: this.state.treeData,
                                path: path, // You can use path from here
                                getNodeKey: ({
                                    node: TreeNode,
                                    treeIndex: number,
                                }) => {
                                    // console.log(number);
                                    return number;
                                },
                                ignoreCollapsed: true,
                            }),
                        });
                    }
                })
                .catch((error) => console.log(error));
        }
    }
    expandNavi(topic_id) {
        console.log(topic_id);
        const result = find({
            getNodeKey: ({node}) => node.id,
            treeData: this.state.treeData,
            searchMethod: (rowInfo) => {
                return rowInfo.node.id === parseInt(topic_id);
            },
            expandAllMatchPaths: true,
            expandFocusMatchPaths: true,
            ignoreCollapsed: false,
        });
        console.log('In EXPAND');
        console.log(result);
        const result2 = map({
            treeData: this.state.treeData,
            callback: ({node, path}) => {
                if (result.matches[0].path.includes(node.id)) {
                    return {...node, expanded: true};
                } else {
                    return {...node};
                }
            },
            getNodeKey: ({treeIndex}) => treeIndex,
            ignoreCollapsed: false,
        });

        this.setState({treeData: result2});

        // this.setState({
        //     treeData: this.state.treeData.map((node) => ({
        //         ...node,
        //         expanded: true, // test.map((node) => node.id).includes(node.id),
        //         className: '',
        //         title: node.label,
        //     })),
        // });
    }
    submitNode(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        let currentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path: path,
            getNodeKey: ({treeIndex}) => treeIndex,
            ignoreCollapsed: true,
        });
        //path.splice(-1);

        let parentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path: path.slice(0, path.length - 1),
            getNodeKey: ({treeIndex}) => treeIndex,
            ignoreCollapsed: true,
        });
        const data = {
            label: document.getElementsByClassName('new_topic')[0][0].value,
            parent_id: parentNode.node.id,
            token: this.state.token,
            workspace_id: parseInt(
                document.getElementsByClassName('mainpanel')[0].dataset
                    .workspace,
            ),
        };

        fetch('/api/add_topic/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.state.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    this.props.updateTopics({
                        label: data['label'],
                        id: data.id,
                        user_id: data.user_id,
                        number_of_posts: 0,
                        parent_id: data.parent_id,
                    });
                    this.setState({
                        treeData: changeNodeAtPath({
                            treeData: this.state.treeData,
                            path: path,
                            newNode: {
                                title: data['label'],
                                id: data.id,
                                number_of_posts: 0,
                                parent_id: data.parent_id,
                                user_id: data.user_id,
                            },
                            getNodeKey: ({treeIndex}) => treeIndex,
                        }),
                    });
                }
            })
            .catch((error) => console.log(error));
        setTimeout(
            function () {
                this.clickHandler();
            }.bind(this),
            50,
        );
        // console.log('Submit Form from Node');

        // way of finding changes in tree structure:
        // const result = find({
        //     getNodeKey: ({treeIndex}) => treeIndex,
        //     treeData: this.state.treeData,
        //     searchMethod: (rowInfo) => {
        //         return rowInfo.node.state === 'new';
        //     },
        // });
        // console.log(result);
    }
    get_topics(id) {
        console.log(id);
        fetch('/api/topics/' + id, {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.state.token,
            },
        })
            .then((response) => response.json())
            .then((topics) => {
                //console.log('componentDidMount');
                //console.log(topics);
                const test = topics.filter((node) => {
                    return node.id === node.parent_id;
                });

                const tree_data = getTreeFromFlatData({
                    flatData: topics.map((node) => ({
                        ...node,
                        expanded: false, // test.map((node) => node.id).includes(node.id),
                        className: test.map((node) => node.id).includes(node.id)
                            ? 'parent'
                            : 'child',
                        title: node.label,
                    })),
                    getKey: (node) => node.id, // resolve a node's key
                    getParentKey: (node) => {
                        if (node.parent_id === node.id) {
                            return '0';
                        } else {
                            return node.parent_id;
                        }
                    }, // resolve a node's parent's key
                    rootKey: '0', // The value of the parent key when there is no parent (i.e., at root level)
                });
                this.setState({treeData: tree_data});
            });
    }
    // componentDidMount() {
    //     setTimeout(function () {
    //         this.props.onClickTopic(this.props.url_topic_id);
    //     }, 200);
    // }
    componentDidUpdate(prevProps) {
        console.log(prevProps);
        console.log(this.props.url_topic_id);
        console.log(this.props.url_topic_id);
        console.log('update');
        console.log(this.props.topic_id);

        if (
            (this.state.treeData.length !== undefined) &
            (this.props.workspace_id !== undefined) &
            (this.state.loaded === true) &
            //  (this.props.topic_id === undefined)
            ((this.props.url_topic_id !== prevProps.url_topic_id) |
                (this.props.topic_id === undefined))
        ) {
            console.log('CLICKITDSASA');
            if (this.props.url_topic_id !== 'all') {
                this.props.onClickTopic(this.props.url_topic_id);
            }
        }
        if (
            (this.state.treeData.length !== undefined) &
            (this.props.topic_id !== undefined &&
                this.props.topic_id !== prevProps.topic_id)
        ) {
            this.expandNavi(this.props.topic_id);
        }

        if (
            (this.state.treeData.title == 'loading') &
            (this.props.workspace_id !== undefined) &
            (this.state.loaded === false)
        ) {
            console.log('PJEOS');
            console.log(this.state.loaded);
            this.setState({loaded: true});

            this.get_topics(prevProps.workspace_id);
        }
    }
    naviSubmitHandler() {
        const flatTreeData = getFlatDataFromTree({
            treeData: this.state.treeData,
            getNodeKey: ({treeIndex}) => treeIndex,
            ignoreCollapsed: false,
        });

        const data = flatTreeData
            .filter((x) => {
                if (x.node.id === x.node.parent_id) {
                    return x.parentNode !== null;
                } else {
                    return x.parentNode === null
                        ? true
                        : x.node.parent_id !== x.parentNode.id;
                }
            })
            .map((x) => {
                return {
                    title: x.node.title,
                    id: x.node.id,
                    parent_id: x.parentNode === null ? null : x.parentNode.id,
                };
            });
        fetch('/api/change_navi/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.state.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    console.log(data);
                }
            })
            .catch((error) => console.log(error));
    }
    // . {
    //     display: inline-block;
    // }

    clickHandler() {
        Array.prototype.map.call(
            document.querySelectorAll(' .rst__moveHandle'),
            // (x) => {
            //     x.style.visibility =
            //         this.state.expandable === true ? 'hidden' : 'visible';
            (x) => {
                x.style.display =
                    this.state.expandable === false ? 'none' : 'inline-block';
            },
        );
        Array.prototype.map.call(
            document.querySelectorAll('.rst__rowContents'),
            // (x) => {
            //     x.style.visibility =
            //         this.state.expandable === true ? 'hidden' : 'visible';
            (x) => {
                x.style.left =
                    this.state.expandable === false ? '-20px' : '0px';
            },
        );
    }
    topicClickHandler(event) {
        this.props.onClickTopic(event.target.id);
        var url = window.location.href;
        url = url.replace(/\/[^\/]*$/, '/' + event.target.id);
        window.history.pushState('', '', url);
    }
    naviChangeHandler(event) {
        this.setState({changable: event.target.checked});

        this.state.expandable = this.state.expandable === true ? false : true;
        this.state.draggable = this.state.draggable === true ? false : true;
        this.clickHandler();
        const titles = this.state.treeData.map((x) => {
            return x.title;
        });

        const empty_field = titles.includes('');

        if (this.state.expandable) {
            if (empty_field === false) {
                this.addSameLevelNode();
            }
        }
    }
    reset() {
        const resetted_tree = map({
            treeData: this.state.treeData,
            callback: ({node, path}) => {
                return {...node, expanded: false};
            },
            getNodeKey: ({treeIndex}) => treeIndex,
            ignoreCollapsed: false,
        });

        this.setState({treeData: resetted_tree});
    }
    expand_all() {
        const resetted_tree = map({
            treeData: this.state.treeData,
            callback: ({node, path}) => {
                return {...node, expanded: true};
            },
            getNodeKey: ({treeIndex}) => treeIndex,
            ignoreCollapsed: false,
        });

        this.setState({treeData: resetted_tree});
    }
    render() {
        const getNodeKey = ({treeIndex}) => treeIndex;

        this.isDragging = false;
        return (
            <div>
                <div class='formcard d-flex justify-content-between mt-2 mb-3 p-2'>
                    <div class=' mx-2 my-auto '>
                        <a
                            href='#'
                            class='link-secondary'
                            onClick={this.reset.bind(this)}
                        >
                            <h4>Navigation: </h4>
                        </a>
                        <a
                            href='#'
                            id='expand_all'
                            class='link-secondary'
                            onClick={this.expand_all.bind(this)}
                            style={{visibility: 'hidden'}}
                        ></a>
                    </div>
                    <div class='form-check form-switch mx-2 my-auto '>
                        {this.state.changable === true ? (
                            <label
                                class='form-check-label '
                                for='flexSwitchCheckDefault'
                            >
                                <a
                                    class='link-secondary navigation'
                                    href='#'
                                    onClick={this.naviSubmitHandler.bind(this)}
                                >
                                    Bestätigen
                                </a>
                            </label>
                        ) : (
                            <label
                                class='form-check-label '
                                for='flexSwitchCheckDefault'
                            >
                                <a class='link-secondary'>Bearbeiten</a>
                            </label>
                        )}

                        <input
                            class='form-check-input'
                            type='checkbox'
                            role='switch'
                            id='flexSwitchCheckDefault'
                            onChange={this.naviChangeHandler.bind(this)}
                        ></input>
                    </div>
                </div>

                <div
                    style={{height: 2000}}
                    className='tree_area  side_navigation'
                >
                    {this.state.loaded === false && (
                        <div class='d-flex justify-content-center'>
                            <div class='spinner-border m-5' role='status'>
                                <span class='sr-only'>Loading...</span>
                            </div>
                        </div>
                    )}
                    <SortableTree
                        treeData={this.state.treeData}
                        onChange={(treeData) => {
                            this.state.width = 200;
                            this.setState({treeData});
                            const treeDepths = getFlatDataFromTree({
                                treeData: treeData,
                                getNodeKey: ({treeIndex}) => treeIndex,
                                ignoreCollapsed: true,
                            }).map((x) => {
                                return x.path.length;
                            });
                            // determine max depth of tree
                            var max_of_array = Math.max.apply(Math, treeDepths);
                            const sidebar =
                                document.getElementsByClassName('sidebar')[0];

                            sidebar.setAttribute('class', 'sidebar');
                            sidebar.classList.add(
                                'col-' + (3 + Math.round(max_of_array / 3)),
                            );
                            const mainpanel =
                                document.getElementsByClassName('mainpanel')[0];
                            mainpanel.setAttribute('class', 'mainpanel');
                            mainpanel.classList.add(
                                'col-' +
                                    (12 - (3 + Math.round(max_of_array / 3))),
                            );

                            setTimeout(
                                function () {
                                    this.clickHandler();
                                }.bind(this),
                                50,
                            );
                        }}
                        onDragStateChanged={({isDragging, draggedNode}) => {
                            this.isDragging = isDragging;
                        }}
                        // generateNodeProps={({node, path}) => {
                        //     return {
                        //         className: `${node.className}`,
                        //     };
                        // }}
                        generateNodeProps={(rowInfo) => ({
                            title:
                                rowInfo.node.title === '' ? (
                                    <form
                                        className='new_topic'
                                        onSubmit={(event) => {
                                            event.preventDefault();

                                            this.submitNode(rowInfo);
                                        }}
                                    >
                                        <div class='form-group'>
                                            <input
                                                class='form-control form-control-sm new_topic'
                                                placeholder='Neues Thema'
                                                type='text'
                                            ></input>
                                        </div>
                                    </form>
                                ) : (
                                    <a
                                        id={rowInfo.node.id}
                                        data-parent_id={rowInfo.node.parent_id}
                                        href='#'
                                        onClick={this.topicClickHandler.bind(
                                            this,
                                        )}
                                        onMouseOver={() => {}}
                                        className='link-secondary navigation'
                                    >
                                        {rowInfo.node.title}
                                        <span class='badge rounded-pill mx-2 info_badge'>
                                            {rowInfo.node.number_of_posts}
                                        </span>
                                    </a>
                                ),

                            buttons:
                                rowInfo.node.title === ''
                                    ? []
                                    : [
                                          <button
                                              className={
                                                  this.state.changable === true
                                                      ? 'rst__addtopic'
                                                      : 'hidden'
                                              } //,'rst__addtopic'
                                              onClick={(event) => {
                                                  this.addNode(rowInfo);
                                              }}
                                          >
                                              {'+'}
                                          </button>,

                                          <button
                                              className={
                                                  this.state.changable === true
                                                      ? 'rst__deltopic'
                                                      : 'hidden'
                                              }
                                              onClick={(event) =>
                                                  this.removeNode(rowInfo)
                                              }
                                          >
                                              {'-'}
                                          </button>,
                                      ],
                        })}
                        canNodeHaveChildren={(node) =>
                            this.isDragging ? node.expanded : true
                        }
                        rowHeight={46}
                    />
                </div>
            </div>
        );
    }
}

// const TopicList = (props) => {
//     let data = Array.from(props.topics);
//     console.log(data);
//     const submitID = (event) => {
//         console.log(event.target.id);
//         props.onClickTopic(event.target.id);
//     };
//     return (
//         <div className='m-2'>
//             {/* Display the article details if article is not None */}
//             {data &&
//                 data.map((topic) => {
//                     return (
//                         <div key={topic.id}>
//                             <a href='#' onClick={submitID} id={topic.id}>
//                                 {topic.label}
//                             </a>
//                         </div>
//                     );
//                 })}
//         </div>
//     );
// };

// export default TopicList;
