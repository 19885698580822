import React, {Component} from 'react';
import Prism from 'prismjs';
import './prism.css';
(typeof global !== 'undefined' ? global : window).Prism = Prism;
require('prismjs/components/prism-r');
require('prismjs/components/prism-python');
require('prismjs/components/prism-sas');

export default class CodeParser extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        //console.log('CODE PARSER');
        //console.log(this.props.object.chunk);
        setTimeout(() => Prism.highlightAll(), 0);
    }
    createMarkup(html_text) {
        //console.log('CODE PARSER');
        //console.log(html_text);
        // var str = html_text.replace(/(?<=<code.+)>/, '>{`');
        // str = str.replace(/<\/code>/, '`}</code>');
        // console.log(str);
        return {__html: html_text};
    }

    render() {
        if (this.props.object.code === false) {
            if (
                this.props.object.chunk.includes('code>') |
                this.props.object.chunk.includes('<code')
            ) {
                return null;
            } else {
                return (
                    <div
                        dangerouslySetInnerHTML={this.createMarkup(
                            this.props.object.chunk,
                        )}
                    ></div>
                );
            }
        } else {
            return (
                <div className='Code'>
                    <pre>
                        <code
                            className={`language-${this.props.object.language}`}
                        >
                            <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                    this.props.object.chunk,
                                )}
                            ></div>
                        </code>
                    </pre>
                </div>
            );
        }
    }
}
