import React, {useRef} from 'react';
import {Editor} from '@tinymce/tinymce-react';

const PostFormular = (props) => {
    const editorRef = useRef(null);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        props.submitHandler(editorRef.current.getContent());
    };
    return (
        <form
            method='post'
            data-topic_id={props.topic_id}
            onSubmit={formSubmitHandler}
            id={props.class}
        >
            <div class={props.class + ' form-group m-4'}>
                <Editor
                    apiKey='dp1orkwkxqukit5a0omvpva0qdxtazs5l03hjbzres8am1bc'
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={props.body}
                    init={{
                        height: 500,
                        menubar: false,

                        content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                        plugins: [
                            'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                            'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                            'save table contextmenu directionality template paste textcolor codesample ',
                        ],
                        images_upload_url: '/api/upload/',
                        automatic_uploads: true,
                        images_reuse_filename: false,
                        images_upload_base_path:
                            'https://datamindset-engels.s3.eu-central-1.amazonaws.com/',
                        toolbar:
                            'example|insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons | codesample ',
                        codesample_languages: [
                            {text: 'HTML/XML', value: 'markup'},
                            {text: 'JavaScript', value: 'javascript'},
                            {text: 'CSS', value: 'css'},
                            {text: 'Python', value: 'python'},
                            {text: 'R', value: 'r'},
                            {text: 'SAS', value: 'sas'},
                            {text: 'bash', value: 'bash'},
                            {text: 'shell', value: 'shell'},
                            {text: 'autohotkey', value: 'autohotkey'},
                        ],
                    }}
                />
                {/* <textarea id='body' name='body' value={props.body}></textarea> */}
            </div>
            <div class='form-group m-4'>
                <select
                    id={props.class + '_select'}
                    class='form-select'
                    aria-label='Default select example'
                >
                    <option value='no_view'>
                        Nur ich darf den Beitrag lesen
                    </option>
                    <option value='no_change'>
                        Nur ich darf den Beitrag ändern / löschen
                    </option>
                    <option value='changeable'>
                        Jeder darf den Beitrag ändern / löschen
                    </option>
                </select>
            </div>
            <div class='d-flex justify-content-center'>
                <button class='btn btn-tableau bg-light' type='submit'>
                    Abschicken
                </button>
            </div>
            <br></br>
        </form>
    );
};

export default PostFormular;
