import {Component} from 'react';
import CodeParser from './CodeParser';
function getIndicesOf(searchStr, str, caseSensitive, add = 1) {
    // add =1 only makes sense because i dont want the beginning of tft but instead the position of the f in tft
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    var startIndex = 0,
        index,
        indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index + add);
        startIndex = index + searchStrLen;
    }
    return indices;
}

const PostParser = (props) => {
    const parsebody = (body) => {
        const languages = body.match(/(?<=language-)[A-za-z]+/g);
        console.log(languages);
        var body_chunks = body.split(/(<code.{0,30}>|<\/code>)/g);

        const bool_list = body_chunks.map((chunk) => {
            return chunk.includes('code>') | chunk.includes('<code')
                ? 't'
                : 'f';
        });

        const bool_string = bool_list.join('');
        const code_positions = getIndicesOf('tft', bool_string);

        // filter empty code positions and neighbors
        body_chunks = Array.prototype.filter.call(
            body_chunks,
            (x, index, elements) => {
                //detect code positions or neighbor code positions:
                if (
                    code_positions.includes(index) |
                    code_positions.includes(index + 1) |
                    code_positions.includes(index - 1)
                ) {
                    if (code_positions.includes(index)) {
                        return x !== '';
                    } else if (code_positions.includes(index + 1)) {
                        return elements[index + 1] !== '';
                    } else {
                        return elements[index - 1] !== '';
                    }
                } else {
                    return true;
                }
            },
        );

        // console.log(
        //     body_chunks[code_positions].map((x) => {
        //         return x == '';
        //     }),
        // );

        let jsons = body_chunks.map((element, index) => {
            return {
                code: code_positions.some((el) => el === index),
                chunk: element.replace(/<pre.+>|<\/pre>/g, ''),
                language:
                    languages &&
                    code_positions.map((el) => el === index).indexOf(true) !==
                        -1
                        ? languages[
                              code_positions
                                  .map((el) => el === index)
                                  .indexOf(true)
                          ]
                        : null,
            };
        });

        // console.log(
        //     jsons.filter((x) => {
        //         return (
        //             (x.chunk.includes('<pre class=') === false) &
        //             (x.chunk.includes('</pre>') === false)
        //         );
        //     }),
        // );

        return jsons;
    };

    return (
        <div>
            {parsebody(props.body).map((obj) => {
                return (
                    <div>
                        <CodeParser object={obj}></CodeParser>
                    </div>
                );
            })}
        </div>
    );
};

export default PostParser;
