import {useEffect} from 'react';
import {useState, useContext} from 'react';
import AuthContext from '../Store/auth-context';

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [valid, setValid] = useState(false);
    const authCtx = useContext(AuthContext);
    const submitHandler = (event) => {
        event.preventDefault();
        if (
            (username.length > 5) &
            ((password != '') & (password.length > 5)) &
            (password === password2)
        ) {
            authCtx.register({username: username, password: password});
        }
    };
    const usernameChangeHandler = (event) => {
        setUsername(event.target.value);
    };
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    };
    const password2ChangeHandler = (event) => {
        setPassword2(event.target.value);
    };
    useEffect(() => {
        if (
            (username.length > 5) &
            ((password != '') & (password.length > 5)) &
            (password === password2)
        ) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [username, password, password2]);
    return (
        <div class='container'>
            <div className='row m-2'>
                <div className='col-6 offset-3'>
                    <form onSubmit={submitHandler}>
                        <div className='form-group my-2'>
                            <label for='username'>Benutzername</label>
                            <input
                                className='form-control '
                                id='username'
                                type='text'
                                value={username}
                                onChange={usernameChangeHandler}
                                placeholder='Benutzername'
                            ></input>
                        </div>

                        <div className='form-group my-2'>
                            <label for='password'>Passwort</label>
                            <input
                                id='password'
                                className='form-control'
                                value={password}
                                onChange={passwordChangeHandler}
                                type='password'
                                placeholder='Passwort eingeben'
                            ></input>
                        </div>

                        <div className='form-group my-2'>
                            <label for='password'>
                                Bestätige dein Passwort
                            </label>
                            <input
                                className='form-control '
                                value={password2}
                                onChange={password2ChangeHandler}
                                type='password'
                                placeholder='Passwort bestätigen'
                            ></input>
                        </div>
                        <br></br>
                        {valid === true ? (
                            <button class='btn btn-primary' type='submit'>
                                Registrieren
                            </button>
                        ) : (
                            <button
                                class='btn btn-primary'
                                disabled
                                type='submit'
                            >
                                Registrieren
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
