import React, {useState} from 'react';

const WorkspaceForm = (props) => {
    const [title, setTitle] = useState('');
    const [visibility, setVisibility] = useState(false);
    const addWorkspaceHandler = (event) => {
        event.preventDefault();
        if (title !== '') {
            const data = {
                group_name: title,
                token: props.token,
            };

            fetch('/api/add_group/', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + props.token,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    props.addGroupHandler(data);
                    document.getElementById('add_workspace').reset();
                })
                .catch((error) => console.log(error));
        }
    };

    const titleChangeHandler = (event) => {
        setTitle(event.target.value);
    };
    const clickHandler = () => {
        if (visibility === false) {
            setVisibility(true);
        } else {
            setVisibility(false);
        }
    };
    return (
        <div class='d-flex justify-content-center'>
            <div
                class={
                    visibility === false
                        ? 'card w-75 bg-secondary'
                        : 'card w-75 bg-secondary'
                }
            >
                <div
                    class={
                        visibility === false
                            ? 'card-header d-flex justify-content-start'
                            : 'card-header d-flex justify-content-start'
                    }
                >
                    <button
                        class='btn btn-outline-primary bg-light text-primary btn-sm'
                        onClick={clickHandler}
                    >
                        {visibility === false
                            ? 'Workspace hinzufügen'
                            : 'Formular einklappen'}
                    </button>
                </div>
                {visibility && (
                    <img
                        src='https://cdn.pixabay.com/photo/2018/03/10/18/03/laptop-3214756_960_720.png'
                        class='card-img-top'
                        alt='...'
                    ></img>
                )}
                {visibility && (
                    <form onSubmit={addWorkspaceHandler} id='add_workspace'>
                        <div class='card-footer d-flex justify-content-between'>
                            <div className=' add_workspace'>
                                <input
                                    className='form-control form-control-sm'
                                    id='workspace_name'
                                    type='text'
                                    size='35'
                                    value={title}
                                    onChange={titleChangeHandler}
                                    placeholder='Neuen Workspace'
                                ></input>
                            </div>
                            <div className='my-auto'>
                                <button
                                    class='btn btn-success btn-sm'
                                    type='submit'
                                >
                                    hinzufügen
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default WorkspaceForm;
