import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './PostForm.css';
import PostFormular from './PostFormular';
const PostForm = (props) => {
    const navigate = useNavigate();

    const [topic, setTopic] = useState(null);
    const [parent, setParent] = useState([]);
    const [visibility, setVisibility] = useState(false);
    const [children, setChildren] = useState([]);
    const [siblings, setSiblings] = useState([]);
    const clickHandler = () => {
        setVisibility(visibility === true ? false : true);
    };
    useEffect(() => {
        console.log(parent);
        setVisibility(false);
        let data = Array.from(props.posts);
        if (data.length > 0) {
            const ids = data.map((post) => {
                return post.topic_id;
            });
            if (ids.every((val, i, arr) => val === arr[0])) {
                const topic = props.topics.filter((topic) => {
                    return topic.id === ids[0];
                });
                const child = props.topics.filter((topic) => {
                    return (
                        (topic.parent_id === ids[0]) &
                        (topic.parent_id !== topic.id)
                    );
                });
                if (topic[0].id === topic[0].parent_id) {
                    setSiblings([]);
                    setParent([]);
                } else {
                    const parent = props.topics.filter((current_topic) => {
                        return current_topic.id === topic[0].parent_id;
                    });

                    const siblings = props.topics.filter((current_topic) => {
                        return (
                            (current_topic.parent_id === parent[0].id) &
                            (current_topic.parent_id !== current_topic.id)
                        );
                    });
                    setSiblings(siblings);
                    setParent(parent);
                }

                setChildren(child);
                setTopic(topic[0]);
            } else {
                setTopic(null);
                setSiblings([]);
                setChildren([]);
                setParent([]);
            }
        } else {
            const topic_id = parseInt(props.topic_id);
            console.log(props.topic_id);
            if (props.topic_id !== undefined) {
                const topic = props.topics.filter((topic) => {
                    return topic.id === topic_id;
                });
                console.log('Possible reason fr bug');
                console.log(topic);
                const child = props.topics.filter((topic) => {
                    return (
                        (topic.parent_id === topic_id) &
                        (topic.parent_id !== topic.id)
                    );
                });

                try {
                    const parent = props.topics.filter((current_topic) => {
                        return current_topic.id === topic[0].parent_id;
                    });
                    console.log(parent);
                    const siblings = props.topics.filter((current_topic) => {
                        return (
                            (current_topic.parent_id === parent[0].id) &
                            (current_topic.parent_id !== current_topic.id)
                        );
                    });

                    setSiblings(siblings);
                    setParent(parent);
                } catch {
                    setSiblings([]);
                    setParent([]);
                }

                setChildren(child);
                setTopic(topic[0]);
            } else {
                setTopic(null);
                setSiblings([]);
                setParent([]);
                setChildren([]);
            }
        }
    }, [props.posts, props.topic_id]);

    const topicClickHandler = (event) => {
        //console.log(event.target.id);
        //console.log(event.target);

        props.updatePosts(
            event.target.id,
            parseInt(event.target.dataset.boolean) === 1,
        );
    };
    const workspaceEditHandler = (event) => {
        // console.log('Workspace Edit Handler');
        // console.log('Workspace id:' + event.target.id);
        //  console.log('Topic id:' + props.topic_id);
        const data = {
            topic_id: parseInt(props.topic_id),
            new_workspace_id: event.target.id,
        };

        fetch('/api/change_workspace/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + props.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('CHANGED WORKSPACE');
                console.log(data);
                navigate('/workspaces');
            })
            .catch((error) => console.log(error));
    };
    const postAddHandler = (content) => {
        const data = {
            topic_id: parseInt(
                document.getElementById('add_post').dataset.topic_id,
            ),
            status: document.getElementById('add_post_select').value,
            body: content,
            token: props.token,
            workspace_id: parseInt(
                document.getElementsByClassName('mainpanel')[0].dataset
                    .workspace,
            ),
        };

        fetch('/api/add_post/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + props.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                props.addPostFrontend(data);
                setVisibility(false);
                document.getElementById('add_post').reset();
            })
            .catch((error) => console.log(error));
    };

    return (
        <div>
            <div
                className={
                    visibility === false
                        ? 'formcard d-flex justify-content-between m-2'
                        : 'formcard d-flex hidden justify-content-between m-2'
                }
            >
                <h4 class='m-2'>
                    {topic !== null && topic !== undefined && topic.length !== 0
                        ? 'Thema: ' + topic.label
                        : 'Gefundene Beiträge'}
                </h4>

                {topic && (
                    <div class='btn-group' role='group'>
                        <button
                            class='btn btn-tableau bg-light m-2'
                            onClick={clickHandler}
                        >
                            {visibility === false
                                ? 'Beitrag schreiben'
                                : 'Formular zuklappen'}
                        </button>
                        {parent.length !== 0 && (
                            <button
                                id={parent[0].id}
                                class='btn btn-tableau bg-light m-2'
                                onClick={topicClickHandler}
                            >
                                Oberthema
                            </button>
                        )}
                        {parent.length !== 0 && (
                            <div class='dropdown m-2'>
                                <a
                                    class='btn btn-tableau bg-light dropdown-toggle'
                                    href='#'
                                    role='button'
                                    id='dropdownMenuLink'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                >
                                    Nebenthemen
                                </a>

                                <ul
                                    class='dropdown-menu'
                                    aria-labelledby='dropdownMenuLink'
                                >
                                    {siblings &&
                                        siblings.map((item, index) => {
                                            return (
                                                <li>
                                                    <a
                                                        id={item.id}
                                                        class='dropdown-item'
                                                        href='#'
                                                        onClick={
                                                            topicClickHandler
                                                        }
                                                    >
                                                        {item.label}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        )}
                        <div class='dropdown m-2'>
                            <a
                                class='btn btn-tableau bg-light dropdown-toggle'
                                href='#'
                                role='button'
                                id='dropdownMenuLink'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                            >
                                Unterthemen
                            </a>

                            <ul
                                class='dropdown-menu'
                                aria-labelledby='dropdownMenuLink'
                            >
                                {children &&
                                    children.map((item, index) => {
                                        return (
                                            <li>
                                                <a
                                                    id={item.id}
                                                    class='dropdown-item'
                                                    href='#'
                                                    onClick={topicClickHandler}
                                                >
                                                    {item.label}
                                                </a>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                        <div class='dropdown m-2'>
                            <a
                                class='btn btn-tableau bg-light dropdown-toggle'
                                href='#'
                                role='button'
                                id='dropdownMenuLink'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                            >
                                Workspace ändern
                            </a>

                            <ul
                                class='dropdown-menu'
                                aria-labelledby='dropdownMenuLink'
                            >
                                {props.groups &&
                                    props.groups.map((item, index) => {
                                        return (
                                            <li>
                                                <a
                                                    id={item.id}
                                                    class='dropdown-item'
                                                    href='#'
                                                    onClick={
                                                        workspaceEditHandler
                                                    }
                                                >
                                                    {item.group_name}
                                                </a>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                        <button
                            id={topic.id}
                            data-boolean={1}
                            class='btn btn-tableau bg-light m-2'
                            onClick={topicClickHandler}
                        >
                            *
                        </button>
                    </div>
                )}
            </div>

            <div
                className={
                    visibility === false ? 'formcard hidden' : 'formcard mt-2'
                }
            >
                <br></br>
                {topic && (
                    <PostFormular
                        submitHandler={postAddHandler}
                        token={props.token}
                        class='add_post'
                        topic_id={topic.id}
                        body=''
                    ></PostFormular>
                )}
            </div>
        </div>
    );
};

export default PostForm;
