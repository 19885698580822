import {Link} from 'react-router-dom';
import {useState, useContext} from 'react';
import AuthContext from '../Store/auth-context';
function Navbar(props) {
    const authCtx = useContext(AuthContext);
    const [visibilitySearch, setVisibilitySearch] = useState(true);
    const [searchTerm, setSearchTerm] = useState(['']);

    const changeHandler = (event) => {
        setSearchTerm(event.target.value);
    };
    const submitHandler = (event) => {
        event.preventDefault();
        props.liftSearchState(searchTerm);
        const highlight = (text) => {
            //const inputText = document.getElementsByClassName('card-body');
            const inputText = document.querySelectorAll('div.card-body');
            const response = Array.prototype.map.call(inputText, (x) => {
                var innerHTML = x.innerHTML;
                // remove previous highlighted values:
                innerHTML = innerHTML.replace(
                    /(<span class="highlight">|<\/span>)/gim,
                    '',
                );
                var reg = new RegExp('[>][^<>.]*' + text + '[^<>.]*[<]', 'gi');
                // var index = innerHTML.toLowerCase().indexOf(text);
                // if (index >= 0) {
                //     innerHTML =
                //         innerHTML.substring(0, index) +
                //         "<span class='highlight'>" +
                //         innerHTML.substring(index, index + text.length) +
                //         '</span>' +
                //         innerHTML.substring(index + text.length);
                //     const re = new RegExp(text, 'gi');
                //     x.innerHTML = innerHTML.replace(
                //         re,
                //         "<span class='highlight'>" +
                //             text.charAt(0).toUpperCase() +
                //             text.slice(1) +
                //             '</span>',
                //     );
                // }
                var result = innerHTML.replace(reg, function (item, exp) {
                    var subRegex = new RegExp(text, 'gi');
                    return item.replace(
                        subRegex,
                        '<span class="highlight">$&</span>',
                    );
                });
                // const regex = new RegExp(text, 'gi');

                // innerHTML = innerHTML.replace(
                //     /(<span class="highlight">|<\/span>)/gim,
                //     '',
                // );

                // const newText = innerHTML.replace(
                //     regex,
                //     '<span class="highlight">$&</span>',
                // );
                // console.log('highlight');
                // console.log(newText);
                x.innerHTML = result;
                return x.innerHTML;
            });
            return response;
        };
        setTimeout(function () {
            if ((searchTerm !== '') & (searchTerm.length > 4)) {
                // Code, der erst nach 2 Sekunden ausgeführt wird
                highlight(searchTerm);
            }
        }, 300);
    };
    const logoutHandler = () => {
        authCtx.logout();
    };
    const buttonChangeHandler = (event) => {
        if (
            document.getElementsByClassName('navbar-toggler')[0]
                .ariaExpanded === 'false'
        ) {
            setTimeout(function () {
                setVisibilitySearch(true);
            }, 500);
        } else {
            setVisibilitySearch(false);
        }
    };
    return (
        <header>
            <nav class='navbar navbar-expand-lg navbar-dark bd-navbar bg-dark'>
                <div class='container-fluid'>
                    <button
                        class='navbar-toggler'
                        type='button'
                        onClick={buttonChangeHandler}
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarNav'
                        aria-controls='navbarNav'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span class='navbar-toggler-icon'></span>
                    </button>
                    <div
                        class='collapse navbar-collapse justify-content-between'
                        id='navbarNav'
                    >
                        <ul class='nav navbar-nav me-auto '>
                            {authCtx.isLoggedIn &&
                                authCtx.role === 'Administrator' && (
                                    <li class='nav-item'>
                                        <Link to='/admin' className='nav-link'>
                                            Admin
                                        </Link>
                                    </li>
                                )}
                            {authCtx.isLoggedIn && (
                                <li class='nav-item'>
                                    <Link to='/workspaces' className='nav-link'>
                                        Workspaces
                                    </Link>
                                </li>
                            )}
                        </ul>
                        {visibilitySearch && (
                            <ul class=' nav navbar-nav position-absolute start-50 top-50 translate-middle'>
                                {authCtx.isLoggedIn && (
                                    <form
                                        class='form-inline '
                                        onSubmit={submitHandler}
                                    >
                                        <input
                                            class='form-control'
                                            type='search'
                                            onChange={changeHandler}
                                            value={searchTerm}
                                            placeholder='Suche in Beiträgen'
                                            aria-label='Search'
                                        ></input>
                                    </form>
                                )}
                            </ul>
                        )}

                        <ul class='nav navbar-nav ms-auto'>
                            {authCtx.isLoggedIn == false && (
                                <li class='nav-item'>
                                    <Link to='/register' className='nav-link'>
                                        Registrieren
                                    </Link>
                                </li>
                            )}
                            {authCtx.isLoggedIn && (
                                <li class='nav-item'>
                                    <Link to='/aufgaben' className='nav-link'>
                                        Aufgaben
                                    </Link>
                                </li>
                            )}
                            {authCtx.isLoggedIn == false ? (
                                <li class='nav-item'>
                                    <Link to='/' className='nav-link'>
                                        Einloggen
                                    </Link>
                                </li>
                            ) : (
                                <li class='nav-item'>
                                    <a
                                        className='nav-link'
                                        href='#'
                                        onClick={logoutHandler}
                                    >
                                        Ausloggen
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
