import React, {useState, useContext, useEffect} from 'react';
import AuthContext from '../Store/auth-context';

const Users = () => {
    const [users, setUsers] = useState([]);
    const authCtx = useContext(AuthContext);
    useEffect(() => {
        fetch('/api/users/', {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data['user_roles']);
                setUsers(data['user_roles']);
            });
    }, []);
    const assignRoleHandler = (event) => {
        console.log(parseInt(event.target.id));
        console.log();

        fetch('/api/change_role/', {
            method: 'POST',
            body: JSON.stringify({
                user_id: parseInt(event.target.id),
                new_role: event.target.dataset.role,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => console.log(error));
    };
    const friendButtonHandler = (event) => {
        fetch('/api/everybodies_friend/', {
            method: 'POST',
            body: JSON.stringify({
                user_id: parseInt(event.target.id),
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => console.log(error));
    };
    const unfollowHandler = (event) => {
        console.log('unfollowHandler');

        fetch('/api/unfollow/', {
            method: 'POST',
            body: JSON.stringify({
                user_id: parseInt(event.target.dataset.user_id),
                friend_id: parseInt(event.target.id),
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => console.log(error));
    };
    return (
        <div class='container my-4'>
            <div className='row'>
                {authCtx.isLoggedIn && (
                    <div className='col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3'>
                        <ul class='list-group'>
                            {users.map((x) => {
                                return (
                                    <li class='list-group-item'>
                                        <div className='row'>
                                            <div className='col-md-3 col-sm-6 col-6'>
                                                {x.username} ({x.role})
                                            </div>
                                            <div className='col-md-9 col-sm-6 col-6 d-flex justify-content-end'>
                                                <div
                                                    class='btn-group'
                                                    role='group'
                                                >
                                                    <button
                                                        type='button'
                                                        class='btn btn-tableau bg-light btn-sm'
                                                        id={x.id}
                                                        onClick={
                                                            friendButtonHandler
                                                        }
                                                    >
                                                        IGV Wiki
                                                    </button>
                                                    <div class='dropdown mx-2'>
                                                        <a
                                                            class='btn btn-tableau bg-light dropdown-toggle btn-sm btn-block'
                                                            href='#'
                                                            role='button'
                                                            id='dropdownMenuLink'
                                                            data-bs-toggle='dropdown'
                                                            aria-expanded='false'
                                                        >
                                                            Kollegen entfernen
                                                        </a>

                                                        <ul
                                                            class='dropdown-menu'
                                                            aria-labelledby='dropdownMenuLink'
                                                        >
                                                            {x.friends.map(
                                                                (item) => {
                                                                    return (
                                                                        <li>
                                                                            <a
                                                                                id={
                                                                                    item.id
                                                                                }
                                                                                data-user_id={
                                                                                    x.id
                                                                                }
                                                                                class='dropdown-item'
                                                                                onClick={
                                                                                    unfollowHandler
                                                                                }
                                                                                href='#'
                                                                            >
                                                                                {
                                                                                    item.username
                                                                                }
                                                                            </a>
                                                                        </li>
                                                                    );
                                                                },
                                                            )}

                                                            {/*  */}
                                                        </ul>
                                                    </div>
                                                    <div class='dropdown'>
                                                        <a
                                                            class='btn btn-tableau bg-light dropdown-toggle btn-sm btn-block'
                                                            href='#'
                                                            role='button'
                                                            id='dropdownMenuLink'
                                                            data-bs-toggle='dropdown'
                                                            aria-expanded='false'
                                                        >
                                                            Rolle zuweisen
                                                        </a>

                                                        <ul
                                                            class='dropdown-menu'
                                                            aria-labelledby='dropdownMenuLink'
                                                        >
                                                            {[
                                                                'Viewer',
                                                                'User',
                                                                'Moderator',
                                                                'Administrator',
                                                            ].map((item) => {
                                                                return (
                                                                    <li>
                                                                        <a
                                                                            data-role={
                                                                                item
                                                                            }
                                                                            id={
                                                                                x.id
                                                                            }
                                                                            class='dropdown-item'
                                                                            onClick={
                                                                                assignRoleHandler
                                                                            }
                                                                            href='#'
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}

                                                            {/*  */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Users;
