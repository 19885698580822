import Moment from 'react-moment';
import PostParser from './PostParser';
import moment from 'moment';
import 'moment/locale/de';
import {useState} from 'react';
import PostFormular from './PostFormular';
const PostList = (props) => {
    let data = Array.from(props.posts);
    function createMarkup(html_text) {
        return {__html: html_text};
    }
    const [editedPost, setEditedPost] = useState(null);
    const deletePostHandler = (event) => {
        fetch('/api/del_post/', {
            method: 'POST',
            body: JSON.stringify({post_id: event.target.id}),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + props.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    props.delPostFrontend(data);
                }
                document.getElementById('add_post').reset();
            })
            .catch((error) => console.log(error));
    };
    const editPostHandler = (event) => {
        setEditedPost(event.target.id);
    };
    const changedPostHandler = (content) => {
        data = {
            topic_id: parseInt(
                document.getElementById('edit_post').dataset.topic_id,
            ),
            status: document.getElementById('edit_post_select').value,
            body: content,
            post_id: parseInt(editedPost),
        };

        fetch('/api/edit_post/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + props.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    props.editPostFrontend(data);
                    console.log(data);
                }
            })
            .catch((error) => console.log(error));
        setEditedPost(null);
    };
    const topicButtonHandler = (event) => {
        console.log(event.target.id);
        props.expandNavi(event.target.id);
        props.updatePosts(event.target.id);
    };
    return (
        <div className='m-2'>
            {data &&
                data.map((post) => {
                    return (
                        <div key={post.id}>
                            <div className='card my-3 py-0'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='my-auto'>
                                        <Moment format='LL'>
                                            {post.created}
                                        </Moment>
                                    </div>
                                    <div class='btn-group' role='group'>
                                        <button
                                            type='button'
                                            className='btn btn-tableau bg-light'
                                            id={post.topic_id}
                                            onClick={topicButtonHandler}
                                        >
                                            Zum Thema
                                        </button>

                                        <button
                                            type='button'
                                            className='btn btn-outline-success mx-3'
                                            id={post.id}
                                            onClick={editPostHandler}
                                        >
                                            Beitrag ändern
                                        </button>
                                        <button
                                            id={post.id}
                                            type='button'
                                            className='btn btn-outline-danger'
                                            onClick={deletePostHandler}
                                        >
                                            Beitrag löschen
                                        </button>
                                    </div>
                                </div>
                                {editedPost && editedPost == post.id ? (
                                    <PostFormular
                                        topic_id={post.topic_id}
                                        class='edit_post'
                                        submitHandler={changedPostHandler}
                                        body={post.body}
                                    ></PostFormular>
                                ) : (
                                    <div
                                        className='card-body my-0 py-0'
                                        id={'post' + post.id}
                                    >
                                        <PostParser
                                            body={post.body}
                                        ></PostParser>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default PostList;
