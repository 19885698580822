import React, {useState, useContext, useEffect} from 'react';
import AuthContext from '../Store/auth-context';

const Tasks = () => {
    const [tasks, setTasks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [dropdown, setDropdown] = useState(false);
    const [taskname, setTaskname] = useState('');
    const authCtx = useContext(AuthContext);
    const uniqueVals = function (items) {
        var lookup = {};
        var result = [];

        for (var item, i = 0; (item = items[i++]); ) {
            var name = item.category;

            if (!(name in lookup)) {
                lookup[name] = 1;
                result.push(name);
            }
        }
        return result;
    };
    useEffect(() => {
        fetch('/api/tasks/', {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                var result = uniqueVals(data);
                document.getElementById('category_field').value = 'Beruf';

                setCategories(result);
                var ordered_todo = result.map((category, index) => {
                    return {
                        id: index,
                        category: category,
                        data: data.filter((x) => {
                            return x.category === category;
                        }),
                    };
                });

                setTasks(ordered_todo);
            });
    }, []);
    const deleteTaskHandler = (event) => {
        fetch('/api/del_task/', {
            method: 'POST',
            body: JSON.stringify({
                id: parseInt(event.target.id),
                category: event.target.dataset.category,
                token: authCtx.token,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['msg']) {
                    alert(data['msg']);
                } else {
                    // First get the right task category:
                    var task_category = tasks.filter((x) => {
                        return x.category === data['category'];
                    });

                    var result = tasks.map((x) => {
                        if (x.category == data['category']) {
                            x.data = task_category[0].data.filter((x) => {
                                return x.id !== parseInt(data.id);
                            });
                            console.log(x.data.length);
                            if (x.data.length === 0) {
                                return [];
                            } else {
                                return x;
                            }
                        } else {
                            return x;
                        }
                    });

                    //var new_array = tasks.slice();
                    //           new_array.filter((x) => {
                    //    return x.id !== parseInt(data.id);
                    //}),
                    setTasks(result);
                }
            })
            .catch((error) => console.log(error));
    };
    const addTaskHandler = (event) => {
        event.preventDefault();
        var category = document.getElementById('category_field').value;
        if ((taskname !== '') & (category !== '')) {
            const data = {
                body: taskname,
                category: category,
                token: authCtx.token,
            };

            fetch('/api/add_task/', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + authCtx.token,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    var category_exists =
                        tasks.filter((task) => {
                            return task.category == data['category'];
                        }).length !== 0;

                    if (tasks.length === 0 || tasks[0].length === 0) {
                        console.log('here');
                        var result = [
                            {
                                id: 0,
                                category: data['category'],
                                data: [data],
                            },
                        ];
                    } else if (category_exists) {
                        var result = tasks.map((x) => {
                            if (x.category == data['category']) {
                                var new_array = x.data.slice();
                                new_array.push(data);
                                x.data = new_array;
                                return x;
                            } else {
                                return x;
                            }
                        });
                    } else {
                        var result = tasks;
                        result.push({
                            id: 999,
                            category: data['category'],
                            data: [data],
                        });
                    }

                    //var new_array = tasks.slice();
                    //new_array.push(data);
                    setTasks(result);
                    var vals = uniqueVals(result);

                    setCategories(vals);
                    setTaskname('');
                })
                .catch((error) => console.log(error));
        }
    };
    const changeHandler = (event) => {
        setTaskname(event.target.value);
    };
    const toggleShow = (event) => {
        if (dropdown === false) {
            setDropdown(true);
        } else {
            setDropdown(false);
        }
    };
    const dropdownClickHandler = (event) => {
        document.getElementById('category_field').value =
            event.target.dataset.category;
        setDropdown(false);
    };
    return (
        <div class='container my-4'>
            <div className='row'>
                {authCtx.isLoggedIn && (
                    <div className='col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3'>
                        <ul class='list-group'>
                            <li class='list-group-item'>
                                <form id='add_task' onSubmit={addTaskHandler}>
                                    <div className='row'>
                                        <div class='input-group input-group-sm mb-3'>
                                            <div class='input-group-prepend'>
                                                <span
                                                    class='input-group-text'
                                                    id=''
                                                >
                                                    Neue Aufgabe
                                                </span>
                                            </div>
                                            <input
                                                type='text'
                                                class='form-control'
                                                aria-label='Small'
                                                aria-describedby='inputGroup-sizing-sm'
                                                onChange={changeHandler}
                                                value={taskname}
                                            ></input>
                                        </div>
                                        <div class='input-group mb-3'>
                                            <span
                                                class='input-group-text'
                                                id=''
                                            >
                                                Kategorie
                                            </span>
                                            <div class='input-group-prepend '>
                                                <button
                                                    type='button'
                                                    id='test'
                                                    class='btn bg-dark text-secondary dropdown-toggle dropdown-toggle-split'
                                                    data-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                    onClick={toggleShow}
                                                >
                                                    <span class='sr-only'></span>
                                                </button>
                                                <div
                                                    class={
                                                        dropdown === false
                                                            ? 'dropdown-menu'
                                                            : 'dropdown-menu show'
                                                    }
                                                >
                                                    {categories &&
                                                        categories.map((x) => {
                                                            return (
                                                                <a
                                                                    class='dropdown-item'
                                                                    href='#'
                                                                    data-category={
                                                                        x
                                                                    }
                                                                    onClick={
                                                                        dropdownClickHandler
                                                                    }
                                                                >
                                                                    {x}
                                                                </a>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                            <input
                                                type='text'
                                                class='form-control'
                                                id='category_field'
                                                aria-label='Text input with segmented dropdown button'
                                            ></input>
                                            <input type='submit' hidden />
                                        </div>
                                    </div>
                                </form>
                            </li>
                            <br></br>
                            <div class='accordion' id='accordionExample'>
                                {tasks &&
                                    tasks.map((task_category) => {
                                        if (task_category.length !== 0) {
                                            var inner_html =
                                                task_category.data.map(
                                                    (task) => {
                                                        return (
                                                            <li class='list-group-item'>
                                                                <div className='row'>
                                                                    <div className='col-md-10 col-sm-6 col-6'>
                                                                        {
                                                                            task.body
                                                                        }
                                                                    </div>
                                                                    <div className='col-md-2 col-sm-6 col-6'>
                                                                        <button
                                                                            id={
                                                                                task.id
                                                                            }
                                                                            data-category={
                                                                                task_category.category
                                                                            }
                                                                            class='btn btn-outline-primary btn-sm '
                                                                            type='submit'
                                                                            onClick={
                                                                                deleteTaskHandler
                                                                            }
                                                                        >
                                                                            erledigt
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    },
                                                );
                                            return (
                                                <div class='accordion-item '>
                                                    <h2
                                                        class='accordion-header'
                                                        id={
                                                            'heading' +
                                                            task_category.id
                                                        }
                                                    >
                                                        <button
                                                            class={
                                                                task_category.id ===
                                                                0
                                                                    ? 'accordion-button '
                                                                    : 'accordion-button  collapsed'
                                                            }
                                                            type='button'
                                                            data-bs-toggle='collapse'
                                                            data-bs-target={
                                                                '#collapse' +
                                                                task_category.id
                                                            }
                                                            aria-expanded='true'
                                                            aria-controls={
                                                                'collapse' +
                                                                task_category.id
                                                            }
                                                        >
                                                            {
                                                                task_category.category
                                                            }
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={
                                                            'collapse' +
                                                            task_category.id
                                                        }
                                                        class={
                                                            task_category.id ===
                                                            0
                                                                ? 'accordion-collapse collapse show'
                                                                : 'accordion-collapse collapse'
                                                        }
                                                        aria-labelledby={
                                                            'heading' +
                                                            task_category.id
                                                        }
                                                        data-bs-parent='#accordionExample'
                                                    >
                                                        <div class='accordion-body'>
                                                            {inner_html}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tasks;
