import {useState, useEffect, useContext} from 'react';
import './App.css';
import PostsSite from './Components/PostsSite';
import Navbar from './Components/Navbar';
import {Routes, Route} from 'react-router-dom';
import AuthContext from './Store/auth-context';
import Login from './Components/Login';
import Register from './Components/Register';
import Workspaces from './Components/Workspaces';
import Tasks from './Components/Aufgaben';
import Users from './Components/Admin';
function App() {
    const [searchFieldInput, setSearchFieldInput] = useState(['']);
    const authCtx = useContext(AuthContext);
    const submitSearchHandler = (data) => {
        if (data !== '') {
            setSearchFieldInput(data);
        }
    };

    return (
        <div className='App'>
            <Navbar liftSearchState={submitSearchHandler}></Navbar>
            <Routes>
                <Route path='/aufgaben' element={<Tasks></Tasks>}></Route>
                <Route
                    path='/workspaces'
                    element={<Workspaces></Workspaces>}
                ></Route>
                <Route path='/admin' element={<Users></Users>}></Route>
                <Route path='/' element={<Login></Login>}></Route>
                <Route
                    path='/posts/:workspace_id/:topic_id'
                    element={
                        authCtx.isLoggedIn && (
                            <PostsSite term={searchFieldInput} />
                        )
                    }
                ></Route>
                <Route path='/register' element={<Register></Register>}></Route>
            </Routes>
        </div>
    );
}

export default App;
